$color__white: #fff;
$color__black: #000;
$color__blackish: #3b3938;
$color__light-grey: #efefef;
$color__lighter-grey: #f5f5f5;
$color__lighter-grey2: #EFF1F3;
$color__border-grey: #cac6c5;
$color__grey: #796f6e;
$color__grey2: #a19a99;
$color__red: #da291c;
$color__blue-grey: #57718a;

$color__twitter: #01aaf0;
$color__facebook: #4267B2;
$color__linkedin: #0072b1;
$color__instagram: #5B51D8;
$color__youtube: #FF0000;

$colors: ( 
  'white': $color__white,
  'black': $color__black,
  'red': $color__red,
  'blue-grey': $color__blue-grey,
  'blackish': $color__blackish,
);

$socials-colors: (
  'insta': $color__instagram,
  'fb': $color__facebook,
  'twitter': $color__twitter,
  'linkedin': $color__linkedin,
  'youtube': $color__youtube
);