html, body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  color: $color__blackish;
  font-family: $font-reg;
  line-height: 1.25;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.app {
  @apply flex flex-col overflow-hidden relative;
  max-width: calc-rem(1920);
  min-height: 100vh;
  margin: 0 auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  @include responsive(lg) {
    &.has-fixed-header {
      padding-top: calc-rem(112);// height header
    }
  }
}

.app-body {
  @include max-responsive(lg) {
    padding-top: calc-rem(66); // height of header;
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

.preload * {
  transition: none!important;
}

a, button {
  transition: .3s ease-in-out color;
}

svg {
  transition: .3s ease-in-out fill;
}