.header {
  padding: .5rem 0;

  @include responsive(lg) {
    padding: calc-rem(14) 0;

    &.is-fixed {
      @apply fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 15;
      background-color: $color__white;
      box-shadow: 0 2px 4px 0 rgba($color__black, 0.1);
      transform: translateY(-100%);

      &.is-visible {
        transform: translateY(0);
      }

      &.has-transition {
        transition: .5s ease-in-out transform;
      }
    }
  }

  @include max-responsive(lg) {
    @apply fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 15;
    background-color: $color__white;
    box-shadow: 0 2px 4px 0 rgba($color__black, 0.1);
  }

  &__item {
    @include responsive(lg) {
      flex: 1;
    }
  }

  &__logo {
    @apply flex justify-center;

    img {
      @include max-responsive(lg) {
        width: 3rem;
      }
    }
  }

  &__panel {
    @include max-responsive(lg) {
      @apply fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 20;
      height: 100vh;
      height: calc((var(--vh, 1vh) * 100));
      background-color: $color__white;
      transform: translateX(-100%);
      transition: .5s ease-in-out transform;

      &.is-visible {
        transform: translateX(0);
      }
    }
  }

  &__panel-top {
    @apply flex items-center justify-between;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #e4e2e2;

    @include responsive(lg) {
      display: none;
    }
  }
}

.header-lang {
  @apply flex items-center;
  color: $color__grey;

  @include max-responsive(lg) {
    flex-grow: 1;
  }

  &__switch {
    @apply relative;
    width: 1.5rem;
    height: calc-rem(50);
    margin-right: .5rem;
    background-color: $color__light-grey;
    border-radius: .75rem;
    box-shadow: 0px 0px 0px 2px $color__border-grey inset;
    cursor: pointer;

    input {
      opacity: 0;
    }

    input:checked + .header-lang__switch-round {
      transform: translateY(26px);
    }

    &-round {
      @apply absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $color__blue-grey;
      border-radius: 50%;
      transition: .3s ease-in-out transform;
    }
  }

  &__text {
    line-height: 1.2;
    text-transform: capitalize;

    &.is-active {
      color: $color__blue-grey;
      font-family: $font-bold;
    }

    @include max-responsive(lg) {
      font-size: calc-rem(14);
    }
  }
}