.nav {
  &__item {
    @include responsive(lg) {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }

    @include max-responsive(lg) {
      margin: 0 1.5rem;
      border-bottom: 1px solid #f0f0f0;
    }
  }

  &__link {
    @apply block w-full text-left;
    line-height: 1.25;

    @include max-responsive(lg) {
      @apply flex items-center;
      padding: 1.25rem 0;
      font-size: 1.25rem;
      font-family: $font-bold;

      span {
        flex-grow: 1;
      }
    }

    @include responsive(lg) {
      @apply text-center;
    }

    svg:not(.nav__arrow) {
      fill: $color__blackish;
    }

    &:hover {
      color: $color__red;

      svg {
        fill: $color__red;
      }
    }
  }

  &__arrow {
    @apply relative;
    top: 3px;
    fill: $color__red;
  }

  &__icon {
    @include max-responsive(lg) {
      position: relative;
      top: 2px;
      margin-right: .5rem;
    }
  }
}