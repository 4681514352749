@use "sass:map";

// CONTAINER CLASSES
.fluid-grid {
  width: calc(100vw - (#{$base-outter-margin} * 2));
  max-width: calc(#{$base-max-container} - (#{$base-outter-margin} * 2));
  margin: 0 auto;

  @each $breakpoint, $max-width in $responsive-max-containers {
    @include responsive($breakpoint) {
      width: calc(100vw - (#{map.get($responsive-outter-margins, $breakpoint)} * 2));
      max-width: calc(#{$max-width} - (#{map.get($responsive-outter-margins, $breakpoint)} * 2));
    }
  }
}

// ROWS CLASSES
.row {
  display: grid;
  grid-column-gap: $base-inner-gutter;
  grid-template-columns: repeat($grid-columns, 1fr);

  @each $breakpoint, $max-width in $responsive-max-containers {
    @include responsive($breakpoint) {
      grid-column-gap: map.get($responsive-inner-gutters, $breakpoint);
    }
  }
}

// COLS CLASSES
// simple columns
@for $i from 1 through $grid-columns {
  .col-#{$i} {
    grid-column-end: span $i;
  }

  .col-offset-#{$i} {
    grid-column-start: calc(#{$i} + 1);
  }
}

// responsive columns
@each $breakpoint, $max-width in $responsive-max-containers {
  @for $i from 1 through $grid-columns {
    @include responsive($breakpoint) {
      .#{$breakpoint}-col-#{$i} {
        grid-column-end: span $i;
      }

      .#{$breakpoint}-col-offset-#{$i} {
        grid-column-start:  calc(#{$i} + 1);
      }
    }
  }
}

.col-underlap {
  z-index: -1;
  grid-row: 1;
}

.col-overlap {
  z-index: 1;
  grid-row: 1;
}