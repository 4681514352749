.sr-only {
  @include sr-only();
}

// avoid broken image symbol
img.lazyload:not([src]) {
  visibility: hidden;
}

.no-scroll-y {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.object-fit-image-wrap {
  @apply absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.object-fit-image {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  /* stylelint-disable-next-line */
  font-family: 'object-fit: cover;'; // Polyfill
}

.icon {
  @each $name, $color in $colors {
    &#{&}--#{$name} {
      fill: $color;
    }
  }

  &--reversed {
    transform: rotate(-180deg);
  }

  &--rotated {
    transform: rotate(-90deg);
  }
}

// use it with js-full-height-mobile
// useful for calculations w/ IOS bottom bar
.full-height-mobile {
  @include max-responsive(md) {
    min-height: 100vh; 
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.left-full {
  width: calc(100% + 3rem);
  max-width: none;
  margin: 0 -1.5rem;

  @include responsive(md) {
    width: calc(100% + 5rem);
    margin: 0 -2.5rem;
  }

  @include responsive(lg) {
    width: calc(100% + 2.5rem);
    margin-right: 0;
  }
}