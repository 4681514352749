@use "sass:map";

@mixin responsive($class) {
  @if map-has-key($breakpoints, $class) {
    $value: map.get($breakpoints, $class);

    @media screen and (min-width: #{$value}) {
      @content;
    }
  }

  @else {
    @warn "Responsive mixin doesn't support this value";
  }
}
  
@mixin max-responsive($class) {
  @if map-has-key($breakpoints, $class) {
    $value: map.get($breakpoints, $class);

    @media screen and (max-width: #{$value - 1}) {
      @content;
    }
  }

  @else {
    @warn "Max responsive mixin doesn't support this value";
  }
}
  