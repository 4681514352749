.footer {
  padding: 1.5rem 0;
  color: $color__white;
  font-size: calc-rem(14);
  background-color: $color__blue-grey;

  &__list {
    gap: .75rem 3rem;
  }
  
  a:hover {
    color: $color__grey2;
  }
}