.button {
  display: inline-block;
  min-height:3rem;
  padding: calc-rem(14) calc-rem(26);
  color: $color__white;
  font-size: 1rem;
  font-family: $font-bold;
  line-height: 1.19;
  text-align: center;
  border-radius: 1.5rem;
  transition: .3s ease-in-out background-color;

  @each $name, $color in $colors {
    &#{&}--#{$name} {
      background-color: $color;
    }
  }

  &:hover {
    background-color: $color__blackish;
  }
}

.button-ghost {
  display: inline-flex;
  align-items: center;
  min-height:3rem;
  padding: calc-rem(6) 1.5rem calc-rem(6) .5rem;
  font-size: 1rem;
  font-family: $font-bold;
  line-height: 1.19;
  border: 2px solid;
  border-radius: 1.5rem;
  transition: .3s ease-in-out border-color, .3s ease-in-out color;

  @each $name, $color in $colors {
    &#{&}--#{$name} {
      color: $color;
      border-color: $color;

      svg {
        fill: $color;
      }
    }
  }

  svg {
    transform: scaleX(-1);
  }

  &:hover {
    color: $color__blackish;
    border-color: $color__blackish;

    svg {
      fill: $color__blackish;
    }
  }
}

.link-icon {
  @apply inline-flex items-center;
  color: $color__red;

  svg {
    position: relative;
    top: 2px;
    margin-left: .25rem;
    fill: currentColor;
  }

  &:hover {
    color: $color__blackish;
  }
}