.bold {
  font-family: $font-bold;
}

.title-page {
  color: $color__white;
  font-size: 2rem;
  line-height: 1.1;
  text-transform: uppercase;

  @include responsive(md) {
    font-size: 2.5rem;
  }
}

.title-2 {
  font-size: calc-rem(26);
  line-height: 1.12;

  @include responsive(md) {
    font-size: calc-rem(34);
  }
}

.paragraph {
  line-height: 1.38;

  p:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  strong {
    font-weight: 400;
    font-family: $font-bold;
  }

  ul {
    margin-left: calc-rem(18);
    list-style-type: disc;
  }

  ol {
    margin-left: calc-rem(18);
    list-style-type: decimal;
  }

  a {
    background-image: linear-gradient(currentColor, currentColor);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 100% 1px;

    &:hover {
      color: $color__red;
    }
  }
  
  @include max-responsive(md) {
    font-size: calc-rem(14);
  }
}
